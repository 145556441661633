<template>
  <v-app-bar app color="primary" dark>
    <span class="logo-icon mb-n15" v-if="showIcon">
      <router-link to="/">
        <img src="../../assets/background/logo600.png" width="100px" />
      </router-link>
    </span>
    <span class="logo-text ml-2" v-if="showIcon">
      COMPONENTES PLANES DE VIDA
    </span>
    <v-app-bar-nav-icon
      class="d-block d-md-none"
      @click="
        $vuetify.breakpoint.smAndDown
          ? setSidebarDrawer(!Sidebar_drawer)
          : $emit('input', !value)
      "
    />
    <v-spacer />
    <v-btn
        color="red"
        x-small
        :to="{
      name : 'alertList'
    }"
        class="mr-5"
    >
      <v-icon small class="mr-1">mdi-account-alert</v-icon>
      ALERTAS DE FUGA
    </v-btn>
    <!---right part -->
    <span class="logo-text ml-2"> {{user.name }} {{user.last_name}} </span>
    <v-avatar>
      <v-icon dark> mdi-account-circle </v-icon>
    </v-avatar>
    <v-menu bottom left transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on">
          <v-icon>mdi-dots-grid</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-if="$canArray(['view_customuser', 'view_group', 'view_permission'])"
          @click="seeConfig()"
          link
        >
          <v-list-item-title>
            <v-icon class="mr-2">mdi-cog</v-icon>
            Configuración general
          </v-list-item-title>
        </v-list-item>
        <v-list-item
            link
        >
          <v-list-item-title>
            <v-icon class="mr-2">mdi-help-circle</v-icon>
            Ayuda
          </v-list-item-title>
        </v-list-item>
        <v-list-item
            link
        >
          <v-list-item-title>
            <v-icon class="mr-2">mdi-information-outline</v-icon>
            Acerca de
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="signOut()" link>
          <v-list-item-title>
            <v-icon class="mr-2">mdi-logout</v-icon>
            Cerrar Sesion
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "Header",

  components: {},

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    userprofile: [
      {
        title: "Mi Cuenta",
        to: {
          name: "account.user",
        },
      },
      { title: "My Balance" },
      { title: "Inbox" },
      { title: "Account Setting" },
      { title: "Logout" },
    ],
    ...mapActions(["signOut"]),
  }),

  computed: {
    ...mapState(["Sidebar_drawer", "user"]),
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER",
    }),
    seeConfig() {
      this.$router.push({
        name: "generalConfig",
      });
    },
  },
};
</script>
<style>
.logo-text {
  font-weight: lighter;
}

.logo-icon {
  z-index: 99;
}
</style>
