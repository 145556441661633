<template>
  <v-navigation-drawer
    v-model="Sidebar_drawer"
    :dark="SidebarColor !== 'white'"
    :color="SidebarColor"
    mobile-breakpoint="960"
    :right="$vuetify.rtl"
    mini-variant-width="70"
    :expand-on-hover="expandOnHover"
    app
    id="main-sidebar"
  >
    <v-list dense nav>
      <!---USer Area -->
      <v-list-item-group v-model="currentRoute">
        <v-list-item class="px-0" two-line>
          <v-list-item-avatar>
            <img src="../../assets/background/logo600.png" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ appName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!---USer Area -->
        <!---Sidebar Items -->
        <template v-for="(item, i) in items">
          <v-list-item
            :key="`menu_item_${i}`"
            v-if="item.permission ? $can(item.permission) : true"
            :to="item.to"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
      <!---Sidebar Items -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import PlanPermissionsEnum from "@/enums/planPermissionsEnum";

export default {
  name: "Sidebar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentRoute: null,
  }),
  computed: {
    ...mapState(["SidebarColor", "SidebarBg", "selectedComponent"]),
    appName() {
      return {
        DDHH: "OBSERVATORIO DV DD.HH.",
        DDHH_WOMEN: "OBSERVATORIO VBG MUJERES",
        SELF_JUSTICE: "JUSTICIA_PROPIA",
        CENSUS: "CENSO INDÍGENA",
        PLANNING: "PLANEACIÓN",
        ORDINARY_JUSTICE: "JUSTICIA ORDINARIA",
      }[this.selectedComponent];
    },
    items() {
      return {
        DDHH: [
          {
            title: "Inicio",
            icon: "mdi-home",
            to: {
              name: "humansRights.dashboard",
            },
          },
          {
            permission: "list_cases_ddhh",
            title: "Lista de Casos",
            icon: "mdi-format-list-checks",
            to: "/humansRights/list-of-cases",
          },
          {
            permission: "view_reference_values_ddhh",
            title: "Valores de referencia",
            icon: "mdi-format-list-checks",
            to: "/humansRights/value-references",
          },
          {
            permission: "view_reports_ddhh",
            title: "Reportes",
            icon: "mdi-chart-bar",
            to: "/humansRights/graphic-reports",
          },
          {
            title: "Volver a componentes",
            icon: "mdi-arrow-left-circle",
            to: "/",
          },
        ],
        DDHH_WOMEN: [
          {
            title: "Inicio",
            icon: "mdi-home",
            to: {
              name: "womenRights.dashboard",
            },
          },
          {
            permission: "add_case_ddhh_women",
            title: "Nuevo Caso",
            icon: "mdi-human-female",
            to: "/womenRights/create/case",
          },
          {
            permission: "list_cases_ddhh_women",
            title: "Lista Casos",
            icon: "mdi-format-list-checks",
            to: "/womenRights/list-of-cases",
          },
          {
            permission: "view_reports_ddhh_women",
            title: "Reportes",
            icon: "mdi-chart-bar",
            to: "/womenRights/graphic-reports",
          },
          {
            permission: "view_reference_values_ddhh_women",
            title: "Valores de referencia",
            icon: "mdi-format-list-checks",
            to: "/humansRights/value-references/true",
          },
          {
            title: "Volver a componentes",
            icon: "mdi-arrow-left-circle",
            to: "/",
          },
        ],
        SELF_JUSTICE: [
          {
            title: "Inicio",
            icon: "mdi-home",
            to: {
              name: "selfjustice.dashboard",
            },
          },
          {
            permission: "list_cases_self_justice",
            title: "Lista Desarmonías",
            icon: "mdi-chart-bar-stacked",
            to: {
              name: "selfjustice.listofdisharmonies",
            },
          },
          {
            title: "Valores de referencia",
            icon: "mdi-format-list-checks",
            to: "/selfjustice/value-references",
          },
          {
            permission: "view_reports_self_justice",
            title: "Reportes",
            icon: "mdi-chart-bar",
            to: "/selfjustice/graphic-reports",
          },
          {
            title: "Volver a componentes",
            icon: "mdi-arrow-left-circle",
            to: "/",
          },
        ],
        CENSUS: [
          {
            title: "Inicio",
            icon: "mdi-home",
            to: {
              name: "census.dashboard",
            },
          },
          {
            permission: "search_commoners",
            title: "Buscar Comunero",
            icon: "mdi-account-search",
            to: {
              name: "census.searchComuner",
            },
          },
          {
            permission: "reports_commoners",
            title: "Reportes",
            icon: "mdi-database",
            to: {
              name: "census.reports",
            },
          },
          {
            title: "Volver a componentes",
            icon: "mdi-arrow-left-circle",
            to: "/",
          },
        ],
        PLANNING: [
          {
            title: "Inicio",
            icon: "mdi-home",
            to: "/planning/dashboard",
          },
          {
            permission: PlanPermissionsEnum.VIEW_LIST_PLANNING,
            title: "Planes",
            icon: "mdi-format-list-bulleted",
            name: "planning.plans",
            to: "/planning/plans",
          },
          {
            title: "Valores de referencia",
            icon: "mdi-format-list-checks",
            to: "/planning/value-references",
          },
          {
            permission: PlanPermissionsEnum.VIEW_REPORTS_PLANNING,
            title: "Reportes",
            icon: "mdi-chart-bar",
            to: "/planning/graphic-reports",
          },
          {
            permission: PlanPermissionsEnum.VIEW_GEOCAPAS_PLANNING,
            title: "Geocapas",
            icon: "mdi-elevation-rise",
            to: "/planning/list-geocapas",
          },
          {
            permission: PlanPermissionsEnum.VIEW_REPORTS_PLANNING,
            title: "Reportes de territorio",
            icon: "mdi-chart-bar",
            to: "/planning/graphic-reports-geojson",
          },
          /* {
            permission: PlanPermissionsEnum.VIEW_PLANNING,
            title: "Valores de referencia",
            icon: "mdi-database-cog",
            to: "/planning/value-references",
          },
           */
          {
            title: "Volver a componentes",
            icon: "mdi-arrow-left-circle",
            to: "/",
          },
        ],
        ORDINARY_JUSTICE: [
          {
            title: "Inicio",
            icon: "mdi-home",
            to: "/ordinary-justice/dashboard",
          },
          {
            permission: "list_cases_ordinary_justice",
            title: "Lista de casos",
            icon: "mdi-format-list-bulleted",
            to: "/ordinary-justice/cases",
          },
          {
            title: "Valores de referencia",
            icon: "mdi-format-list-checks",
            to: "/ordinary-justice/value-references",
          },
          {
            permission: "view_reports_ordinary_justice",
            title: "Reportes",
            icon: "mdi-chart-bar",
            to: "/ordinary-justice/graphic-reports",
          },
          {
            title: "Volver a componentes",
            icon: "mdi-arrow-left-circle",
            to: "/",
          },
        ],
      }[this.selectedComponent];
    },
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      },
    },
  },
  watch: {
    "currentRoute"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },

  methods: {},
};
</script>
<style lang="scss">
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);

  .v-navigation-drawer__border {
    display: none;
  }

  .v-list {
    padding: 8px 15px;
  }

  .v-list-item {
    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }
}

#main-sidebar .v-list {
  padding-top: 0px;
}
</style>
